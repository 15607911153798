import * as _getUserLocale3 from "get-user-locale";

var _getUserLocale2 = "default" in _getUserLocale3 ? _getUserLocale3.default : _getUserLocale3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatShortMonth = exports.formatMonth = void 0;
exports.getFormatter = getFormatter;

var _getUserLocale = _interopRequireDefault(_getUserLocale2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var formatterCache = new Map();

function getFormatter(options) {
  return function (locale, date) {
    var localeWithDefault = locale || (0, _getUserLocale["default"])();

    if (!formatterCache.has(localeWithDefault)) {
      formatterCache.set(localeWithDefault, new Map());
    }

    var formatterCacheLocale = formatterCache.get(localeWithDefault);

    if (!formatterCacheLocale.has(options)) {
      formatterCacheLocale.set(options, new Intl.DateTimeFormat(localeWithDefault, options).format);
    }

    return formatterCacheLocale.get(options)(date);
  };
}
/**
 * Changes the hour in a Date to ensure right date formatting even if DST is messed up.
 * Workaround for bug in WebKit and Firefox with historical dates.
 * For more details, see:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=750465
 * https://bugzilla.mozilla.org/show_bug.cgi?id=1385643
 *
 * @param {Date} date Date.
 */


function toSafeHour(date) {
  var safeDate = new Date(date);
  return new Date(safeDate.setHours(12));
}

function getSafeFormatter(options) {
  return function (locale, date) {
    return getFormatter(options)(locale, toSafeHour(date));
  };
}

var formatMonthOptions = {
  month: "long"
};
var formatShortMonthOptions = {
  month: "short"
};
var formatMonth = getSafeFormatter(formatMonthOptions);
exports.formatMonth = formatMonth;
var formatShortMonth = getSafeFormatter(formatShortMonthOptions);
exports.formatShortMonth = formatShortMonth;
export default exports;